#app-footer{
    padding-top: 40px;
    padding-bottom: 90px;
    display: block;
    width: 100%;
    float: left;
    background: #E4EEF1 0% 0% no-repeat padding-box;
    min-height: 140px;
    color: #313131;
    flex: none;

    @media screen and (min-width: $breakpoint-medium) {
        padding-top: 55px;
    }

    h4{
        font-family: $font-semi-bold;
        font-size: 16px;
        color: #313131;
        margin-bottom: 5px;
        margin-block-start: 0em;
    }
    .wide-container {
        display: flex;
        justify-content: center;
        &.kmr{
            @media screen and (min-width: $breakpoint-medium) {
                justify-content: flex-start;
            }
        }
        /*@media screen and (min-width: $breakpoint-medium) {
            justify-content: center;
        }*/

        .contact-wrapper{
            padding-right: 0px;
            display: inline-block;
            padding-bottom: 40px;
            line-height: 20px;


            @media screen and (min-width: $breakpoint-medium) {
                padding-bottom: 20px;
            }

            @media screen and (min-width: $breakpoint-large) {
                display: block;
                width: auto;
                padding-right: 0px;
                padding-bottom: 0;
            }

            .contact-info{
                @media screen and (min-width: $breakpoint-medium) {
                    display: flex;
                }
            }

            p{
                margin-block-start: 0em;
                margin-block-end: 15px;

                @media screen and (min-width: $breakpoint-medium) {
                    margin-block-end: 5px;
                }
            }
            .location{
                font-size: 14px;
                background: url('../../../assets/images/location.png') no-repeat;
                padding: 0px 35px;
                margin-top: 20px;
                min-height: 30px;
            }
            .phone-wrapper{
                margin-top: 25px;
                padding-left: 35px;
                margin-left: 0px;
                @media screen and (min-width: $breakpoint-medium) {
                    //margin-left: 45px;
                    margin-top: 13px;
                }
                &.kmr{
                    @media screen and (min-width: $breakpoint-medium) {
                        margin-left: 45px;
                    }
                }

                &.phone-icon{
                    background: url('../../../assets/images/phone.png') no-repeat;
                }
                .phone{
                    color:#313131;
                    font-size: 20px;
                    padding-top: 4px;
                    @media screen and (min-width: $breakpoint-medium) {
                        font-size: 18px;
                    }
                }
                .contact-face{
                    font-size: 14px;
                    margin-top: 8px;
                }
            }
            .facebook{
                background: url('../../../assets/images/fb.png') no-repeat;
                height: 23px;
                font-size: 14px;
                margin-top: 25px;
                padding-left: 35px;
                color: #039CEB;
                cursor: pointer;
            }
            .email{
                color:#039CEB;

            }

            .copyright{
                display: none;
                @media screen and (min-width: $breakpoint-large) {
                    display: block;
                }
                .copy-container{
                    margin-top: 0px;
                    padding-top: 0px;
                    img{
                        padding-right: 0px;

                    }
                    span{
                        border-left: none;
                        padding-left: 13px;
                        width: 116px;
                        font-size: 11px;
                    }
                }
            }

            .developed{
                margin-right: 0px;
                padding-left: 30px;
                span{
                    color: #039CEB;
                    margin-left: 11px;
                    cursor: pointer;
                }
            }

        }

        .play-market-wrapper{
            padding-left: 0px;
            padding-top: 40px;
            padding-bottom: 40px;
            border-top: solid 1px #A5B5B9 ;
            width: 100%;
            float: left;

            @media screen and (min-width: $breakpoint-medium) {
                border-top: none;
                padding-left: 20px;
                padding-top: 20px;
                padding-bottom: 20px;
            }

            @media screen and (min-width: $breakpoint-large) {
                padding-left:50px;
                padding-top:0px;
                padding-bottom: 0px;
                border: 0;
                float: none;
                width: auto;
            }
            p{
                margin-top: 20px;
                color: #313131;
                margin-bottom: 20px;

                @media screen and (min-width: $breakpoint-small) {
                    margin-top: 0;
                }

            }
            img {
                cursor: pointer;
            }
        }
        img {
            width: auto;
            height: auto;
        }
    }

    .social-network-container{
        padding: 40px 0px;
        border:none;
        display: inline-block;
        width: 100%;
        border-top: solid 1px #A5B5B9;
        @media screen and (min-width: $breakpoint-medium) {
            width: 40%;
            border:none;
            padding: 0px 0px;
        }

        @media screen and (min-width: $breakpoint-large) {
            display: block;
            width:auto;
            border-left: solid 1px #A5B5B9;
            border-right: solid 1px #A5B5B9;
            padding: 0 50px;
        }
    }

    .social-network-block{
        display: flex;
        margin-top: 20px;
    }

    .social-network{
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 39px;
        height: 39px;
        border: solid 2px  #039CEB;
        border-radius: 50%;
        color: #039CEB;
        font-family: $font-bold;
        margin-right: 32px;

        &:last-child{
            margin-right: 0px;
        }

        &.facebook{
            font-size: 29px;
        }
        &.linkedIn{
            font-size: 20px;
        }
        &.twitter{
            font-size: 25px;
        }
    }
    .row{
        display: flex;
        justify-content: space-between;
        max-width: 1125px;

        align-items: center;
         border-top: solid 1px #A5B5B9 ;
        margin-left: $general-margin-small;
        margin-right: $general-margin-small;
        flex-direction: column;

        @media screen and (min-width: $breakpoint-medium) {
            flex-direction: row;
            display: flex;
        }
        @media screen and (min-width: $breakpoint-large) {
            margin: 0 auto;
            border-top: none;
            display: none;
        }
    }

}

.developed{
    max-width: 158px;
    margin-top: 42px;
    display: flex;
    align-items: center;
    font-size: 12px;
    @media screen and (min-width: $breakpoint-medium) {
        margin-top: 25px;
    }
    @media screen and (min-width: $breakpoint-large) {
        margin-top: 40px;
    }
    span{
        padding-right: 20px;
    }
}


.copy-container{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0px;
    padding-top: 25px;
    float: left;
    flex-direction: column;
    @media screen and (min-width: $breakpoint-medium){
        padding-top: 25px;
        flex-direction: row;
    }

    @media screen and (min-width: $breakpoint-large) {
        padding-top: 0px;
        margin: 40px 0 0 0;
        border: 0;
    }

    .vertical-line{
        height: 15px;
        width: 0;
        border: solid 1px #313131;
        margin: 0 20px;

        @media screen and (min-width: $breakpoint-medium){
            margin: 0 35px;
        }
    }
    img{
        width: auto;
        height: auto;
        padding-right: 25px;
    }
    span{
        font-size: 12px;
        color:#313131;
        margin-top: 9px;
        @media screen and (min-width: $breakpoint-medium){
            margin-top: 0px;
            border-left: 1px solid #313131;
            padding-left: 25px;
        }
    }
}
