#app-header{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .header-city{
        display: flex;
        align-items: center;
        height: 440px;

      &.hight-image{
        background-image: none;
        background-color: #E4EEF1;
        filter:blur(0);
        transition: filter .3s;
        &.kmr{
          display: flex;
          align-items: center;
          justify-content: space-around;  
          @media screen and (min-width: $breakpoint-medium ) {
            background-image: url('/img/banner_1920.jpg');
          }
          @media screen and (min-width: $breakpoint-large ) {
            background-image: url('/img/banner_1920.jpg');
          }
          @media screen and (min-width: $breakpoint-extra-large ) {
            background-image: url('/img/banner_1920.jpg');
          }
        }
        &.klsh{
          @media screen and (min-width: $breakpoint-medium ) {
            background-image: url('/img/kalush3.jpg');
          }
          @media screen and (min-width: $breakpoint-large ) {
            background-image: url('/img/kalush3.jpg');
          }
          @media screen and (min-width: $breakpoint-extra-large ) {
            background-image: url('/img/kalush3.jpg');
          }
        }
        &.nadv{
          @media screen and (min-width: $breakpoint-medium ) {
            background-image: url('/img/nadvirna-background.jpg');
          }
          @media screen and (min-width: $breakpoint-large ) {
            background-image: url('/img/nadvirna-background.jpg');
          }
          @media screen and (min-width: $breakpoint-extra-large ) {
            background-image: url('/img/nadvirna-background.jpg');
          }
        }
        @media screen and (min-width: 360px){
          height: 361px;
        }

        @media screen and (min-width: $breakpoint-medium ) {
          background-color: none;
          background-image: url('/img/banner_768.png');
          background-repeat: no-repeat;
          background-size: cover;
          height: 279px;
        }

        @media screen and (min-width: $breakpoint-large ) {
          background-image: url('/img/banner_1200.png');
          height: 361px;
        }

        @media screen and (min-width: $breakpoint-extra-large ) {
          background-image: url('/img/banner_1920.png');
          height: 448px;
        }
      }

      &.low-image{
        background-image: none;
        background-color: #E4EEF1;
        @media screen and (min-width: 360px){
          height: 361px;
        }

        @media screen and (min-width: $breakpoint-medium ) {
          background-color: none;
          filter: blur(15px);
          height: 279px;
        }

        @media screen and (min-width: $breakpoint-large ) {
          height: 361px;
        }

        @media screen and (min-width: $breakpoint-extra-large ) {
          height: 448px;
        }
      }
      .container{
        margin-left: 24px;
        margin-right: 24px;
        &.kmr{
          @media screen and (min-width: $breakpoint-medium) {
            flex: 2;
          }  
        }
        @media screen and (min-width: $breakpoint-medium) {
          margin-left: $general-margin-medium;
          margin-right: $general-margin-medium;
        }
        @media screen and (min-width: $breakpoint-large) {
          margin-left: 125px;
          margin-right: 125px;
        }

        @media screen and (min-width: $breakpoint-extra-large) {
          margin-left: $general-margin;
          margin-right: $general-margin;
        }
      }

      .inspection-logo {
        // background-image: none;

        flex: 1;
                
        img {

          display: none;

          width: auto;
          height: 200px;

          margin-left: auto;
          margin-right: auto;          

          @media screen and (min-width: $breakpoint-medium) {
            display: block;
          }          
        }

        // @media screen and (min-width: $breakpoint-medium) {
        //   background-color: none;
        //   background-image: url('/img/inspection_logo.png');
        //   background-repeat: no-repeat;
        //   background-size: cover;
        //   height: 279px;

        //   flex: 1;
        // }
      }


        h1{
            font-family: $font-semi-bold;
            font-size: 23px;
            max-width: 420px;
            color: #313131;
            line-height: 1.3em;
            margin-block-end: 0;

            @media screen and (min-width: $breakpoint-medium) {
                text-shadow: 0px 3px 15px #000000;
                color: #fff;
                font-family: $font-bold;
                max-width: 350px;
            }

            @media screen and (min-width: $breakpoint-large) {
                font-size: 28px;
                max-width: 400px;
            }

            @media screen and (min-width: $breakpoint-extra-large) {
                font-size: 30px;
                max-width: 500px;
            }
        }

        p{
            font-family: $font-medium;
            font-size: 14px;
            color: #313131;
            margin-block-start: 35px;
            margin-block-end: 0px;
            line-height: 1.5em;
            max-width: 650px;
            font-weight: normal;

            @media screen and (min-width: $breakpoint-medium) {
                font-family: $font-semi-bold;
                color:#fff;
                max-width: 600px;
                margin-block-start: 19px;
                margin-block-end: 1rem;
            }

            @media screen and (min-width: $breakpoint-large) {
                font-size: 16px;
                margin-block-start: 35px;
                max-width: 650px;
            }

            @media screen and (min-width: $breakpoint-extra-large) {
                margin-block-end: 68px;
            }
        }
    }

}
